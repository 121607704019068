/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Custom CSS */

/* For DATATABLE */
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: white;
}
.dt-buttons {
  float: left;
}
table.dataTable tbody td {
  padding: 8px 10px;
  color: #666;
  vertical-align: middle;
  font-weight: 300;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #ddd !important;
  border-radius: 0px !important;
}

/* Left Sidebar */
.am-sideleft-menu {
  padding: 10px 20px 10px 0px;
}
.am-sideleft-menu > .nav-item > .nav-link {
  padding-left: 20px;
}
.am-sideleft-menu > .nav-item > .nav-link.active {
  background-color: antiquewhite;
}

/* LeftSidebar icons sizing */
.am-sideleft-menu > .nav-item > .nav-link i:first-child {
  font-size: 17px;
}

/* Input field  */
.form-control,
.dataTables_filter input {
  font-size: 0.85rem;
  font-weight: 300;
}
label {
  font-weight: 300;
  color: #666;
}

/* primereact */

.reactDataTable {
  text-align: right !important;
  margin-bottom: 5px;
}
.reactDataTable button {
  margin-left: 5px;
  width: 2rem !important;
  height: 2rem !important;
}

.p-component {
  font-size: 0.9rem;
}

/* td {
  border: 1px solid #eee;
}

th {
  border: 1px solid #ccc;
  border-top: none;
} */

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  /* overflow: hidden; */
  width: 100%;
}

html {
  font-size: 14px;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: #e9edf0;
  /* background: var(--surface-ground); */
  color: var(--text-color);
  /* padding: 1rem; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard .card {
  overflow: hidden;
}

.p-datatable-header {
  border-bottom: 0px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 1px;
}

.text-xs {
  border: none;
}

.searching {
  margin-left: 20px;
  border: none;
}

.searching .p-inputtext {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: #4b5563;
  background: transparent;
  padding: 2px;
  border: none;
  border-bottom: 1px solid;
  transition: background-color 0.3s, color 0.2s;
  appearance: none;
  border-radius: 0;
  outline-color: transparent;
}

.headerIcons {
  display: inline;
}

.card {
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.am-header {
  background: #fff;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.3rem 1rem;
}

.am-pagebody {
  padding: 15px 35px 5px;
}

/* .p-progress-spinner {
  position: relative;
  height: 90vh;
  display: inline-block;
} */

.dashLoad {
  /* height: 90vh; */
  width: 100%;
  /* margin: 0 auto; */
}
.dashLoad svg {
  height: 90vh;
  width: 8%;
  margin: 0 auto;
}

ol, ul {
  padding-left: 0;
}
